import "./index.scss";

import { Controller as BaseController } from "stimulus";
import Cookies from "js-cookie";
import Sortable from "sortablejs";
import { patch } from "@rails/request.js";

export default class extends BaseController {
  static classes = ["collapsed", "asideCollapsedBg", "mobileHidden"];

  static targets = ["mobileClose", "navElement", "sectionLabel", "editButtonText", "entityItems", "firmItems"];

  static values = {
    dragHandle: String,
    updatePreferencesUrl: String,
    entityOrder: Array,
    firmOrder: Array,
  };

  connect() {
    this.element.controller = this;

    const asideCollapsed = Cookies.get("aside_collapsed") === "true";
    if (asideCollapsed) {
      this.element.classList.add(this.collapsedClass);
      this.sectionLabelTarget.classList.add(this.collapsedClass);
    } else {
      this.element.classList.remove(this.collapsedClass);
      this.sectionLabelTarget.classList.remove(this.collapsedClass);
    }

    this.navElementTarget.scrollTop = 0;
    this.#closeAsideMobile();

    if (this.hasEntityItemsTarget) {
      this.entitySortable = new Sortable(this.entityItemsTarget, {
        group: this.entityItemsTarget.id,
        disabled: false, // Disables the sortable if set to true.
        store: {
          /**
           * Get the order of elements. Called once during initialization.
           * @param   {Sortable}  sortable
           * @returns {Array}
           */
          get: function (sortable) {
            return this.entityOrderValue;
          }.bind(this),
          /**
           * Save the order of elements. Called onEnd (when the item is dropped).
           * @param {Sortable}  sortable
           */
          set: function (sortable) {
            patch(this.updatePreferencesUrlValue, {
              body: { item: sortable.el.id, order: sortable.toArray(), setting: "order" },
            });
          }.bind(this),
        },
        animation: 150, // ms, animation speed moving items when sorting, `0` — without animation
        handle: this.dragHandleValue, // Drag handle selector within list items
        preventOnFilter: true, // Call `event.preventDefault()` when triggered `filter`
        ghostClass: "bg-gray-300", // Class name for the drop placeholder
        direction: "vertical", // Direction of Sortable (will be detected automatically if not given)
      });
    }

    this.firmSortable = new Sortable(this.firmItemsTarget, {
      group: this.firmItemsTarget.id,
      disabled: false, // Disables the sortable if set to true.
      store: {
        /**
         * Get the order of elements. Called once during initialization.
         * @param   {Sortable}  sortable
         * @returns {Array}
         */
        get: function (sortable) {
          return this.firmOrderValue;
        }.bind(this),
        /**
         * Save the order of elements. Called onEnd (when the item is dropped).
         * @param {Sortable}  sortable
         */
        set: function (sortable) {
          patch(this.updatePreferencesUrlValue, {
            body: { item: sortable.el.id, order: sortable.toArray(), setting: "order" },
          });
        }.bind(this),
      },
      animation: 150, // ms, animation speed moving items when sorting, `0` — without animation
      handle: this.dragHandleValue, // Drag handle selector within list items
      preventOnFilter: true, // Call `event.preventDefault()` when triggered `filter`
      ghostClass: "bg-gray-300", // Class name for the drop placeholder
      direction: "vertical", // Direction of Sortable (will be detected automatically if not given)
    });
  }

  disconnect() {
    if (this.entitySortable) {
      this.entitySortable.destroy();
      this.entitySortable = null;
    }

    if (this.firmSortable) {
      this.firmSortable.destroy();
      this.firmSortable = null;
    }
  }

  toggleMobileAside() {
    if (this.element.classList.contains(this.mobileHiddenClass)) {
      this.element.classList.remove(this.mobileHiddenClass);
      this.toggleAsideBackground({ detail: { expanded: true } });
    } else {
      this.element.classList.add(this.mobileHiddenClass);
      this.toggleAsideBackground({ detail: { expanded: false } });
    }
  }

  toggleNav() {
    this.element.classList.toggle(this.collapsedClass);
    const toggled = this.element.classList.contains(this.collapsedClass);
    this.dispatch("toggleNav", {
      detail: {
        toggled: toggled,
      },
    });

    this.#toggleAside(toggled);
  }

  toggleAsideBackground({ detail: { expanded } }) {
    for (const element of document.getElementsByClassName(this.asideCollapsedBgClass)) {
      if (expanded) {
        element.classList.remove("hidden");
      } else {
        element.classList.add("hidden");
        document.querySelectorAll("[data-controller='navigation--menu--item']").forEach((element) => {
          if (element.controller) {
            element.controller.collapseAsideHidden();
          }
        });
      }
    }
  }

  asideMobileClose(event) {
    this.#closeAsideMobile();
    event.stopPropagation();
    event.preventDefault();
  }

  asideClick(event) {
    if (
      (!event.target.attributes["data-header-target"] ||
        event.target.attributes["data-header-target"].value !== "mobileAsideOpenTrigger") &&
      (event.target.classList.contains(this.asideCollapsedBgClass) || !this.element.contains(event.target))
    ) {
      this.#closeAsideMobile();
    }
  }

  toggleEditMode() {
    const editMode = this.navElementTarget.getAttribute("data-edit-mode");
    if (editMode === "true") {
      this.navElementTarget.removeAttribute("data-edit-mode");
      this.editButtonTextTarget.textContent = this.editButtonStandartTextValue;
    } else {
      this.editButtonStandartTextValue = this.editButtonTextTarget.textContent;
      this.navElementTarget.setAttribute("data-edit-mode", "true");
      this.editButtonTextTarget.textContent = `Close ${this.editButtonStandartTextValue}`;
    }
  }

  #toggleAside(toggled) {
    const itemsControllerElements = this.element.querySelectorAll("[data-controller='navigation--menu--item']");
    itemsControllerElements.forEach((element) => {
      element.controller.toggleAside(toggled);
    });
    if (toggled) {
      this.sectionLabelTarget.classList.add(this.collapsedClass);
    } else {
      this.sectionLabelTarget.classList.remove(this.collapsedClass);
    }
  }

  #closeAsideMobile() {
    this.toggleAsideBackground({ detail: { expanded: false } });
    this.element.classList.add(this.mobileHiddenClass);
  }
}
