import "./index.scss";

import { Controller as BaseController } from "stimulus";
import Cookies from "js-cookie";
import Sortable from "sortablejs";
import { patch } from "@rails/request.js";

export default class extends BaseController {
  static values = {
    hasChildren: Boolean,
    expandedIcon: String,
    collapsedIcon: String,
    dragHandle: String,
    updatePreferencesUrl: String,
    childrenOrder: Array,
  };

  static classes = ["collapsed", "activeLink", "asideHidden", "asideExpandedItem", "navHiddenItem"];

  static targets = [
    "titleElement",
    "childrenElement",
    "collapsibleIcon",
    "hrefElement",
    "collapsedIconElement",
    "collapsedMenuElement",
    "visibilityCheckbox",
  ];

  connect() {
    this.element.controller = this;
    if (this._isCurrentPageActive()) {
      this._expandChildren(true);
    } else {
      this._disableActiveLink();
    }

    this.toggleAside(Cookies.get("aside_collapsed") === "true");
    this.collapseAsideHidden();

    // Event listeners for custom events
    this.element.addEventListener("collapseAllAsideHidden", this.handleCollapseAllAsideHidden.bind(this));
    this.element.addEventListener("disableAllActiveLinks", this.handleDisableAllActiveLinks.bind(this));
  }

  toggleAside(toggled) {
    if (toggled) {
      this.element.classList.add(this.asideHiddenClass);
      this.collapsedIconElementTarget.classList.remove("hidden");
      if (this.hasCollapsedMenuElementTarget) {
        this.collapsedMenuElementTarget.classList.remove("hidden");
      }
    } else {
      this.element.classList.remove(this.asideHiddenClass);
      this.collapsedIconElementTarget.classList.add("hidden");
      this.element.classList.remove(this.asideExpandedItemClass);
      if (this.hasCollapsedMenuElementTarget) {
        this.collapsedMenuElementTarget.classList.add("hidden");
      }
    }
  }

  toggle(event) {
    if (
      this.hasChildrenValue &&
      this.hasChildrenElementTarget &&
      (this.titleElementTarget.contains(event.target) || this.collapsibleIconTarget.contains(event.target))
    ) {
      this._toggleChildren();
    }
  }

  expandAsideHidden() {
    if (!this.hasChildrenValue) {
      this.hrefElementTarget.click();
      return;
    }

    // Dispatch event to collapse other controllers
    const event = new CustomEvent("collapseAllAsideHidden", { detail: { expandedItem: this }, bubbles: true });
    this.element.dispatchEvent(event);

    this.element.classList.add(this.asideExpandedItemClass);

    if (this.hasCollapsedMenuElementTarget) {
      for (const element of this.collapsedMenuElementTarget.children) {
        element.classList.remove("hidden");
      }
    }
    this.dispatch("toggleAsideHidden", {
      detail: { expanded: true },
    });
  }

  collapseAsideHidden() {
    this.element.classList.remove(this.asideExpandedItemClass);
    if (this.hasCollapsedMenuElementTarget) {
      for (const element of this.collapsedMenuElementTarget.children) {
        element.classList.add("hidden");
      }
    }
  }

  handleCollapseAllAsideHidden(event) {
    // Collapse if this is not the expanded item
    if (event.detail.expandedItem !== this) {
      this.collapseAsideHidden();
    }
  }

  childrenElementTargetConnected(element) {
    // Map children's default order based on data-id shown order on code and data-default-order attr
    const childrenDefaultOrder = {};
    for (let child of element.querySelectorAll("[data-default-order]")) {
      childrenDefaultOrder[child.dataset.id] = child.dataset.defaultOrder;
    }

    this.sortable = new Sortable(element, {
      group: element.id,
      disabled: false,
      store: {
        get: function (sortable) {
          if (this.childrenOrderValue.length !== 0) {
            return this.childrenOrderValue;
          }

          // Set default order for new or unpersisted items
          for (let childId in childrenDefaultOrder) {
            if (!this.childrenOrderValue.includes(childId)) {
              if (childrenDefaultOrder[childId] === "bottom") {
                this.childrenOrderValue.push(childId);
              } else {
                this.childrenOrderValue.unshift(childId);
              }
            }
          }
          return this.childrenOrderValue;
        }.bind(this),
        set: function (sortable) {
          patch(this.updatePreferencesUrlValue, {
            body: { item: sortable.options.group.name, order: sortable.toArray(), setting: "order" },
          });
        }.bind(this),
      },
      animation: 150,
      handle: this.dragHandleValue,
      preventOnFilter: true,
      ghostClass: "bg-gray-300",
      direction: "vertical",
    });
  }

  hrefElementTargetConnected(element) {
    if (
      this.hasChildrenValue &&
      (!element.getAttribute("href") || element.getAttribute("href") === "#" || element.getAttribute("href") === "")
    ) {
      this._setHrefFromFirstChild();
    }
  }

  visibilityCheckboxTargetConnected(element) {
    // Stop click event propagation to prevent navigation
    element.addEventListener("click", (event) => {
      event.stopPropagation();
    });
  }

  updateVisibility(event) {
    if (event.target.checked) {
      this.element.classList.remove(this.navHiddenItemClass);
    } else {
      this.element.classList.add(this.navHiddenItemClass);
    }

    patch(this.updatePreferencesUrlValue, {
      body: { item: event.target.name, setting: "visibility", visibility: event.target.checked ? 1 : 0 },
    });
  }

  enableActiveLink() {
    // Dispatch event to disable other active links
    const event = new CustomEvent("disableAllActiveLinks", { detail: { activeItem: this }, bubbles: true });
    this.element.dispatchEvent(event);

    this.hrefElementTarget.classList.add(this.activeLinkClass);
    this._enableParentActiveLink();
  }

  handleDisableAllActiveLinks(event) {
    if (event.detail.activeItem !== this) {
      this._disableActiveLink();
    }
  }

  _isCollapsed() {
    return this.hasChildrenValue && this.childrenElementTarget.classList.contains(this.collapsedClass);
  }

  _collapseChildren() {
    if (!this._isCollapsed()) {
      this.childrenElementTarget.classList.add(this.collapsedClass);
      if (this.hasCollapsibleIconTarget) {
        this.collapsibleIconTarget.classList.remove(this.expandedIconValue);
        this.collapsibleIconTarget.classList.add(this.collapsedIconValue);
      }
    }
  }

  _expandChildren(enableParentActiveLink = false) {
    if (enableParentActiveLink) {
      this._enableParentActiveLink();
    }
    if (this._isCollapsed()) {
      this.childrenElementTarget.classList.remove(this.collapsedClass);
      if (this.hasCollapsibleIconTarget) {
        this.collapsibleIconTarget.classList.remove(this.collapsedIconValue);
        this.collapsibleIconTarget.classList.add(this.expandedIconValue);
      }
    }
  }

  _toggleChildren() {
    this._isCurrentPageActive();

    if (!this.hasChildrenElementTarget) {
      return;
    }

    if (this._isCollapsed()) {
      this._expandChildren();
    } else {
      this._collapseChildren();
    }
  }

  _isCurrentPageActive() {
    const activeHrefList = this.hrefElementTarget.getAttribute("data-active-href-list") + ",";
    const hrefUrl = new URL(this.hrefElementTarget.getAttribute("href"), window.location.origin);
    let currentPage = false;
    if (
      hrefUrl.pathname + hrefUrl.search === window.location.pathname + window.location.search ||
      hrefUrl.href === window.location.href ||
      (activeHrefList && activeHrefList.includes(window.location.href + ","))
    ) {
      this.hrefElementTarget.classList.add(this.activeLinkClass);
      currentPage = true;
    }

    return currentPage;
  }

  _disableActiveLink() {
    this.hrefElementTarget.classList.remove(this.activeLinkClass);
  }

  _enableParentActiveLink() {
    const parentController = this._getParentController();
    if (parentController) {
      if (!parentController._getParentController()) { // this should only be true for the top parent
        parentController.hrefElementTarget.classList.add(this.activeLinkClass);
      }
      parentController._enableParentActiveLink();
      parentController.childrenElementTarget.classList.remove(this.collapsedClass);
      if (parentController.hasCollapsibleIconTarget) {
        parentController.collapsibleIconTarget.classList.remove(this.collapsedIconValue);
        parentController.collapsibleIconTarget.classList.add(this.expandedIconValue);
      }
    }
  }

  _getParentController() {
    const parentElement = this.element.parentElement;
    if (parentElement !== null) {
      const parentControllerElement = parentElement.closest(`[data-controller=${this.identifier}]`);
      if (parentControllerElement !== null && parentControllerElement.controller !== undefined) {
        return parentControllerElement.controller;
      }
    }
    return null;
  }

  _setHrefFromFirstChild() {
    // Set href from the first visible child with a valid href
    for (let child of this.childrenElementTarget.querySelectorAll("a[href]:not([href='#'])")) {
      if (child.getAttribute("href") && child.getAttribute("href") !== "#" && child.offsetParent !== null) {
        this.hrefElementTarget.setAttribute("href", child.getAttribute("href"));
        if (child.getAttribute("target")) {
          this.hrefElementTarget.setAttribute("target", child.getAttribute("target"));
        }
        if (child.getAttribute("data-turbo-stream")) {
          this.hrefElementTarget.setAttribute("data-turbo-stream", child.getAttribute("data-turbo-stream"));
        }
        break;
      }
    }
  }
}
