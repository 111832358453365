import "./index.scss";
import Cookies from "js-cookie";
import { Controller as BaseController } from "stimulus";

export default class extends BaseController {
  static classes = ["collapsed"];

  static targets = ["entitySwitcher", "entityDropdown"];

  connect() {
    const asideCollapsed = Cookies.get("aside_collapsed") === "true";
    if (asideCollapsed) {
      this.#hideAside();
    } else {
      this.#showAside();
    }
  }

  disconnect() {}

  toggleAside({ detail: { toggled } }) {
    if (toggled) {
      this.#hideAside();
    } else {
      this.#showAside();
    }
  }

  #hideAside() {
    this.entitySwitcherTarget.classList.add(this.collapsedClass);
    this.entityDropdownTarget.classList.add(this.collapsedClass);
  }

  #showAside() {
    this.entitySwitcherTarget.classList.remove(this.collapsedClass);
    this.entityDropdownTarget.classList.remove(this.collapsedClass);
  }
}
