import "./index.scss";

import { Controller as BaseController } from "stimulus";

export default class extends BaseController {
  connect() {
    if (document.cookie.indexOf("files_viewer_pact_banner_disabled=true") !== -1) {
      this.element.classList.add("hidden");
    } else {
      this.element.classList.remove("hidden");
    }
  }

  disconnect() {}

  oneYearFromToday() {
    const today = new Date();
    const oneYearFromToday = new Date(today);

    oneYearFromToday.setFullYear(today.getFullYear() + 1);
    return oneYearFromToday.toUTCString();
  }

  hideBanner() {
    this.element.classList.add("hidden");
    const expireDate = this.oneYearFromToday();
    document.cookie = "files_viewer_pact_banner_disabled=true; path=/; expires=" + expireDate;
  }
}
