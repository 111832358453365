import "./index.scss";

import { Controller as BaseController } from "stimulus";

export default class extends BaseController {
  connect() {
    document.getElementById("announcement-banner-link").addEventListener("click", this.setLeftSideMenuCookieTrue);
  }

  setLeftSideMenuCookieTrue() {
    document.cookie = "leftSideMenu=true";
  }
}
