import "./index.scss";

import { Controller as BaseController } from "stimulus";

export default class extends BaseController {
  connect() {}

  disconnect() {}

  click(event) {
    // We use this to avoid expanding items when clicking on the action button...
    event.stopPropagation();
  }
}
