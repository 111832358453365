import { Application } from "@hotwired/stimulus";

// Controllers from components
const component_controllers = import.meta.glob("./**/index.js", { eager: true });

const application = Application.start();

for (let path in component_controllers) {
  let module = component_controllers[path];

  let name = path.match(/\.\/(.+)\/index\.js$/);
  if (name !== null) {
    name = name[1].replace(/\//g, "--").replace(/_/g, "-");

    if (module.default) {
      application.register(name, module.default);
    }
  }
}
