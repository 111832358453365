import "./index.scss";

import { Controller as BaseController } from "stimulus";

export default class extends BaseController {
  static targets = ["monthlyElement", "yearlyElement", "monthlyButton", "yearlyButton"];

  hideMonthly(e) {
    this.monthlyButtonTarget.classList.remove("active");
    this.yearlyButtonTarget.classList.add("active");

    this.monthlyElementTargets.forEach((element) => {
      element.classList.add("hidden");
    });
    this.yearlyElementTargets.forEach((element) => {
      element.classList.remove("hidden");
    });
  }

  hideYearly() {
    this.monthlyButtonTarget.classList.add("active");
    this.yearlyButtonTarget.classList.remove("active");

    this.yearlyElementTargets.forEach((element) => {
      element.classList.add("hidden");
    });
    this.monthlyElementTargets.forEach((element) => {
      element.classList.remove("hidden");
    });
  }
}
